.auth {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.code-num-box-visible-first {
  position: absolute;
  width: 4vw;
  height: 4vw;
  top: 1vw;
  z-index: 999;
  left: 1vw;
  opacity: 0;
}

.MuiFormLabel-root.Mui-focused{
  color: black !important;
}

.MuiFormLabel-root,
.MuiInputBase-root{
  font-family: 'Heebo', sans-serif !important;
}

.MuiInput-underline:after{
  border-bottom: 2px solid transparent !important;
}

.MuiInputBase-input {
  font-size: 34px !important;
  font-family: 'Heebo', sans-serif !important;
}


.MuiInputLabel-formControl {
  top: 15px !important;
  left: 25px !important;
  font-size: 17px !important;
  float: right;
  right: 0;
  text-align: right;
  direction: rtl;
  position: absolute;
  transform: translate(0, 24px) scale(1);
}

.err-message {
  position: absolute;
  font-size: 13px;
  top: 70px;
  right: 5px;
  color: red;
}

.MuiInputLabel-shrink {
  transform: translate(70px, -4.5px) scale(0.75) !important;
  transform-origin: top right;
  text-align: right;
}

.code-num-box-visible-second {
  position: absolute;
  width: 4vw;
  height: 4vw;
  top: 1vw;
  left: 7vw;
  opacity: 0;
  z-index: 999;
}
.code-num-box-visible-third {
  position: absolute;
  width: 4vw;
  height: 4vw;
  top: 1vw;
  left: 14vw;
  opacity: 0;
  z-index: 999;
}
.code-num-box-visible-fourth {
  position: absolute;
  width: 4vw;
  height: 4vw;
  top: 1vw;
  left: 20vw;
  opacity: 0;
  z-index: 999;
}

.mobile .auth-logo {
  width: 25vw;
}

.sub-title {
  font-size: 1.1vw;
}

.auth-titles {
  position: fixed;
  top: 10vw;
}

.auth-titles > h1 {
  margin: 0;
  font-weight: 500;
  position: relative;
  bottom: 54px;
}

.auth-box {
  position: relative;
  width: 1000px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-logo {
  width: 11vw;
  position: fixed;
  top: 2vw;
}

.auth-box > h1 {
  font-weight: 900;
}

.phone-box {
  width: 100%;
  outline: none;
  border-radius: 35px;
  text-align: center;
  font-size: 2vw;
  padding: 0.7vw;
  font-family: "Heebo", sans-serif;
}


.phone-field {
  position: fixed;
  display: flex;
  width: 24%;
  flex-direction: column;
}
.out {
  animation: SlideOut 1s both;
}

@keyframes SlideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(2000px);
  }
}

.mobile .phone-box {
  width: 62%;
  outline: none;
  border: 1px solid black;
  border-radius: 29px;
  height: 9vw;
  text-align: left;
  padding: 2vw;
  font-size: 7vw;
}

.code-box {
  width: 25%;
  outline: none;
  border: none;
  height: 3vw;
  text-align: left;
  font-size: 2vw;
  border-bottom: 4px solid black;
  margin: 3vw;
  animation: fadeIn 1s;
}

.auth-code-btn {
  background-color: black;
  color: white;
  border: 2px solid;
  width: 14vw;
  height: 3vw;
  font-size: 2vw;
  animation: fadeIn 1s;
}

.message {
  position: relative;
  font-size: 1vw;
  position: relative;
  bottom: 5vw;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.auth-code-btn:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.mobile .code-box::placeholder {
  font-size: 3vw;
  text-align: center;
}

.mobile .phone-box::placeholder {
  font-size: 5vw;
  text-align: center;
}

.mobile .code-box {
  width: 31%;
  outline: none;
  border: 1px solid black;
  height: 10vw;
  text-align: left;
  font-size: 7vw;
  /* border-bottom: 4px solid black; */
  margin: 34vw;
  animation: fadeIn 1s;
  border-radius: 25px;
}

.mobile .message {
  position: relative;
  top: 1vw;
  font-size: 4.5vw;
  animation: fadeIn 1s;
  margin-top: 2vw;
}

.mobile .auth-code-btn {
  background-color: black;
  color: white;
  border: 2px solid;
  width: 69vw;
  height: 13vw;
  font-size: 7vw;
  animation: fadeIn 1s;
}

.mobile .send-btn {
  width: 25vw;
  margin-right: 1vw;
  height: 12vw;
  border: 2px solid black;
  background-color: black;
  font-size: 5.3vw;
  color: white;
  top: -2px;
  position: relative;
  border-radius: 30px;
}

.code-box::placeholder {
  font-size: 1vw;
  text-align: center;
}

.phone-box::placeholder {
  font-size: 1.5vw;
  text-align: center;
}

.code-area {
  position: relative;
  top: 1vw;
  animation: SlideIn 1s;
}

@keyframes SlideIn {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0);
  }
}

.code-num-box-before {
  border: 2px solid black;
  width: 4vw;
  background-color: white;
  height: 4vw;
  margin: 1vw;
  border-radius: 50%;
  font-size: 2.7vw;
  text-align: center;
  outline: none;
  color: black;
}
.code-num-box-before:focus {
  border: 2px solid rgb(248 74 114);
}

.code-num-box-after {
  border: 2px solid rgb(248 74 114);
  width: 4vw;
  background-color: rgb(248 74 114);
  height: 4vw;
  margin: 1vw;
  border-radius: 50%;
  font-size: 2.7vw;
  text-align: center;
  outline: none;
  color: white;
}

.send-btn {
  width: 5vw;
  height: 4.4vw;
  border: none;
  background-color: white;
  font-size: 1.3vw;
  outline: none;
  position: relative;
  border-radius: 30px;
  top: 8vw;
}

.send-btn:hover {
  cursor: pointer;
}
