body, html, #root{
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
}

body > *{
  font-family: 'Heebo', sans-serif;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

button, input{
  font-family: 'Heebo', sans-serif;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: rgb(36, 35, 35);

}
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0);
  transition: 0.2s;

}