.report {
    height: 100%;
  }
  
  
  .header {
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 999;
  }
  
  .report > div> main {
    width: 78%;
    padding: 1vw;
  }
  